/*
 * ネットワーク状態から、通知メッセージを表示する
 *  - navigator.connection API を利用
 */
function updateConnectionStatus(event) {
  // navigator.connection API に対応しているかどうか
  if ('navigator' in window && 'connection' in navigator) {
    if (navigator.onLine) {
      const connection = navigator.connection;
      // 低速 (2Mbps未満)
      if (connection.downlink < 2) {
        setConnectionMessage(false, 'インターネット接続が低速です。\nネットワーク環境をご確認ください。');
      } else {
        setConnectionMessage(true);
      }
    } else {
      // オフライン
      setConnectionMessage(false, 'インターネット接続がありません。\nご利用の際はインターネットへ接続してください。');
    }
  } else {
    // navigation.connection 未対応の場合は イベントで判定
    if (event && event.type === 'offline') {
      setConnectionMessage(false, 'インターネット接続がありません。\nご利用の際はインターネットへ接続してください。');
    } else {
      setConnectionMessage(true);
    }
  }
}

function setConnectionMessage(isGoodConnection, message) {
  const messageElement = document.getElementById('network-connection-message');
  if (!messageElement) {
    return;
  }

  if (isGoodConnection) {
    messageElement.style.display = 'none';
    messageElement.textContent = '';
  } else {
    messageElement.style.display = 'block';
    messageElement.textContent = message;
  }
}

// オンライン時、オフライン時
if ('ononline' in window && 'onoffline' in window) {
  window.addEventListener('online', updateConnectionStatus);
  window.addEventListener('offline', updateConnectionStatus);
}

// 接続状況変化時
if ('navigator' in window && 'connection' in navigator) {
  navigator.connection.addEventListener('change', updateConnectionStatus);
}
