import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  run(event) {
    // Turbo がリクエストを送る際に、リクエストのきっかけになったボタンを自動的に disabled に
    // してしまうが、refresh_new が高速に何度も実行されるようなときに困るので、
    // 絶対にクリックできるように disabled を外してから click する
    if (event.params.buttonIndex) {
      this.buttonTargets[Number(event.params.buttonIndex)].removeAttribute('disabled');
      this.buttonTargets[Number(event.params.buttonIndex)].click();
    } else {
      this.buttonTargets[0].removeAttribute('disabled');
      this.buttonTargets[0].click();
    }
  }
}
