import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  scrollToInvalid() {
    const element = document.getElementsByClassName('is-invalid')[0];
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
