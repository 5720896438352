"use strict";
$.fn.timepicker.defaults = $.extend(true, {}, $.fn.timepicker.defaults, {
    icons: {
        up: 'fal fa-angle-up',
        down: 'fal fa-angle-down'
    },
    defaultTime: '',
    minuteStep: 1,
    showMeridian: false,
    showInputs: true,
    template: 'dropdown',
});
