'use strict';

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output js/scripts.bundle
 */

require('./plugins');

// Keentheme"s plugins
window.KTUtil = require('./global/components/base/util');
window.KTApp = require('./global/components/base/app');
window.KTAvatar = require('./global/components/base/avatar');
window.KTDialog = require('./global/components/base/dialog');
window.KTHeader = require('./global/components/base/header');
window.KTMenu = require('./global/components/base/menu');
window.KTOffcanvas = require('./global/components/base/offcanvas');
window.KTPortlet = require('./global/components/base/portlet');
window.KTScrolltop = require('./global/components/base/scrolltop');
window.KTToggle = require('./global/components/base/toggle');
window.KTWizard = require('./global/components/base/wizard');
require('./global/components/base/datatable/core.datatable');
require('./global/i18n/datetable_ja.js');
require('./global/components/base/datatable/datatable.checkbox');
// require("global/components/base/datatable/datatable.rtl");

require('./global/lib/network_detector.js');

// Layout scripts
window.KTLayout = require('./global/layout/layout');
window.KTLib = require('./global/layout/lib');

// Color Customize
window.KTAppOptions = {
  'colors': {
    'state': {
      'brand': '#5578eb',
      'metal': '#c4c5d6',
      'light': '#ffffff',
      'accent': '#00c5dc',
      'primary': '#5867dd',
      'success': '#34bfa3',
      'info': '#36a3f7',
      'warning': '#ffb822',
      'danger': '#fd3995',
      'focus': '#9816f4',
    },
    'base': {
      'label': [
        '#c5cbe3',
        '#a1a8c3',
        '#3d4465',
        '#3e4466',
      ],
      'shape': [
        '#fafafa',
        '#d9dffa',
        '#afb4d4',
        '#646c9a',
      ],
    },
  },
};

const formAlert = function() {
  const shortCutFunction = $('#AlertType').val();
  const msg = $('#AlertMessage').val();
  if (shortCutFunction) {
    swal.fire({
      type: shortCutFunction,
      title: msg,
      showConfirmButton: false,
      timer: 2000,
    });
  }
};

const toolTip = function() {
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });
};

const Honeybadger = require('honeybadger-js/dist/honeybadger.min.js');
Honeybadger.configure({
  apiKey: gon.honeybadger_api_key,
  environment: gon.environment,
  revision: 'git SHA/project version',
});

Honeybadger.beforeNotify(function() {
  const ua = navigator.userAgent;
  if (/Trident/.test(ua)) return false; // IEを使用している場合は通知を行わない
  if (/GSA/.test(ua)) return false; // GSAを使用している場合は通知を行わない
});

import {formStorage} from './global/lib/form_storage';
const clearLocalStorage = function() {
  formStorage.clearExpiredStorage();
  const localStorageCompanyID = localStorage.getItem('company_id');
  const validateCompanyId = location.pathname !== '/' && localStorageCompanyID !== gon.company_id;
  if (gon.should_clear_local_storage || validateCompanyId) {
    localStorage.clear();
  }
};

import {init as initCommaSeparetedNumber} from './global/lib/comma_separated_number';

const convertJqueryEventToDomEvent = function(eventName, parameters) {
  const handler = (...args) => {
    const data = {};
    parameters.forEach((name, i) => data[name] = args[i]);
    const delegatedEvent = new CustomEvent('jquery:' + eventName, {
      bubbles: true,
      cancelable: true,
      detail: data,
    });
    data.event.target.dispatchEvent(delegatedEvent);
  };
  $(document).on(eventName, handler);
};

$(document).ready(function() {
  formAlert();
  toolTip();
  clearLocalStorage();
  require('./global/layout/aside-secondary');
  // require("./global/layout/demo-panel");
  require('./global/layout/offcanvas-panel');
  require('./global/layout/quick-panel');
  // require("./global/layout/quick-search");
  initCommaSeparetedNumber();

  convertJqueryEventToDomEvent('show.bs.select', ['event']);
  convertJqueryEventToDomEvent('hidden.bs.select', ['event']);
  convertJqueryEventToDomEvent('hide', ['event']);
  convertJqueryEventToDomEvent('hide.timepicker', ['event']);
});

// submitされないよう、Enterを無効にする
$(function() {
  // TODO ファイル選択をEnter起因で行えるようにする
  $('input:not(.search-control)').keydown(function(e) {
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
      return false;
    } else {
      return true;
    }
  });
});

const addTurboAfterStreamRenderCallback = () => {
  document.addEventListener('turbo:before-stream-render', (event) => {
    const originalRender = event.detail.render;

    event.detail.render = async function(streamElement) {
      await originalRender(streamElement);

      const afterRenderEvent = new Event('turbo:after-stream-render', event.detail);
      document.dispatchEvent(afterRenderEvent);
    };
  });
};
document.addEventListener('DOMContentLoaded', addTurboAfterStreamRenderCallback);

import './controllers';
