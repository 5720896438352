/* eslint-disable require-jsdoc */
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $('.kt_selectpicker').selectpicker();

    $('.kt-datepicker').datepicker({
      startView: 'months',
      todayHighlight: false,
      autoclose: true,
      format: 'yyyy/mm/dd',
      language: 'ja',
      disableTouchKeyboard: true,
      clearBtn: true,
    });

    $('.kt-timepicker').timepicker({});
  }

  closeTimepicker(event) {
    $(event.target).timepicker('hideWidget');
  }
}
