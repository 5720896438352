'use strict';

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output css/style.bundle
 */

// keen styles
import './style.scss';

// original styles
import './application.scss';
