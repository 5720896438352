import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.focusedElement = null;
  }

  add(event) {
    const targetElement = event.currentTarget;
    if (!targetElement) {
      throw new Error('Element not found');
    }

    if (targetElement !== this.focusedElement && this.focusedElement) {
      delete this.focusedElement.dataset.turboPermanent;
    }
    targetElement.dataset.turboPermanent = true;
    this.focusedElement = event.currentTarget;
  }

  remove(event) {
    const targetElement = event.currentTarget;
    if (!targetElement) {
      throw new Error('Element not found');
    }

    delete targetElement.dataset.turboPermanent;
    this.focusedElement = null;
  }
}
