"use strict";

var KTQuickPanel = function() {
    var panel = KTUtil.get('kt_quick_panel');
    var notificationPanel = KTUtil.get('kt_quick_panel_tab_notifications');
    var actionsPanel = KTUtil.get('kt_quick_panel_tab_actions');
    var settingsPanel = KTUtil.get('kt_quick_panel_tab_settings');

    var getContentHeight = function() {
        var height;
        var nav = KTUtil.find(panel, '.kt-offcanvas-panel__nav');
        var content = KTUtil.find(panel, '.kt-offcanvas-panel__body');

        height = parseInt(KTUtil.getViewPort().height) -
                 parseInt(KTUtil.actualHeight(nav)) -
                 parseInt(KTUtil.css(nav, 'margin-bottom')) -
                 (2 * parseInt(KTUtil.css(nav, 'padding-top'))) -
                 10;

        return height;
    }

    var initOffcanvas = function() {
        var offcanvas = new KTOffcanvas(panel, {
            overlay: true,
            baseClass: 'kt-offcanvas-panel',
            closeBy: 'kt_quick_panel_close_btn',
            toggleBy: ['kt_quick_panel_toggler_btn', 'kt_quick_panel_toggler_btn_sp']
        });
    }

    var initNotifications = function() {
        KTUtil.scrollInit(notificationPanel, {
            disableForMobile: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return getContentHeight();
            }
        });
    }

    var initActions = function() {
        KTUtil.scrollInit(actionsPanel, {
            disableForMobile: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return getContentHeight();
            }
        });
    }

    var initSettings = function() {
        KTUtil.scrollInit(settingsPanel, {
            disableForMobile: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function() {
                return getContentHeight();
            }
        });
    }

    var updatePerfectScrollbars = function() {
        $(panel).find('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            KTUtil.scrollUpdate(notificationPanel);
            KTUtil.scrollUpdate(actionsPanel);
            KTUtil.scrollUpdate(settingsPanel);
        });
    }

    return {
        init: function() {
            initOffcanvas();
            // initNotifications();
            // initActions();
            // initSettings();
            // updatePerfectScrollbars();
        }
    };
}();

// Init on page load completed
KTUtil.ready(function() {
    KTQuickPanel.init();
});
