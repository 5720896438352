;(function($){
	$.fn.kTDatatable.defaults.translate = {
		records: {
			processing: '読み込み中...',
			noRecords: 'データが存在しません',
		},
		toolbar: {
			pagination: {
				items: {
					default: {
						first: '最初へ',
						prev: '前へ',
						next: '次へ',
						last: '最後へ',
						more: 'さらにページを表示',
						input: 'ページ番号',
						select: 'ページ表示数',
						all: '全て',
					},
					info: '{{start}} - {{end}}件 / {{total}}件',
				},
			},
		},
	};
}(jQuery));
